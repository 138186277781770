<template>
  <v-card>
    <div v-if="!showDetailsModal">
      <v-card-text
        class="pt-12 px-0"
      >
        <div
          class="container-header"
        >
          <span>
            <h1>Esse é o seu Painel de Documentos!</h1>
            <p>Clique no campo desejado para visualizar o documento.</p>
          </span>

          <div class="image-content">
            <v-img
              class="overlay-image"
              src="@/assets/images/avatars/10.png"
              max-height="200"
              max-width="200"
              contain
            />
            <v-img
              src="@/assets/images/svg/sqg-document-header.svg"
              class="image-header"
              max-height="300"
              max-width="200"
              contain
            />
          </div>
        </div>
      </v-card-text>

      <div
        v-if="isLoadingDocuments"
        class="d-flex justify-center py-10"
      >
        <v-progress-circular
          size="50"
          color="white"
          indeterminate
        />
      </div>

      <div
        v-show="!isLoadingDocuments"
        class="cards-container"
      >
        <div
          v-for="(element, index) in MenuData"
          :key="index"
          class="card"
        >
          <v-icon size="30">
            {{ element.icon }}
          </v-icon>
          <h1>{{ element.title }}</h1>
          <p>{{ element.subtitle }}</p>

          <v-btn
            color="purple"
            width="130px"
            height="30px"
            outlined
            @click="element.handleSubmit()"
          >
            <span style="font-size: 12px;">
              VISUALIZAR
            </span>
          </v-btn>
        </div>
      </div>
    </div>

    <TableOfDocuments
      v-if="showDetailsModal"
      :documents="filteredDocuments"
      :document-type="documentType"
      @close-window="showDetailsModal = false"
    />
  </v-card>
</template>

<script>
import {
  mdiAccountMultipleOutline,
  mdiBookOpenVariant,
  mdiFileDocument,
  mdiPaperclip,
  mdiShieldAccountVariant,
} from '@mdi/js'

import { sgqDocumentFlux } from '@/assets/images/svg/sgqDocument'
import axiosIns from '@/plugins/axios'
import messages from '@/plugins/showMessageMixin'
import TableOfDocuments from './TableOfDocuments.vue'

export default {
  components: {
    TableOfDocuments,
  },

  mixins: [messages],

  data() {
    return {
      documentType: '',

      documents: [{ id: '' }],
      documentTypes: [],
      filteredDocuments: [],

      showDetailsModal: false,
      isLoadingDocuments: false,

      MenuData: [{
        title: 'ATR',
        subtitle: 'Atribuição',
        icon: mdiAccountMultipleOutline,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('ATR') },
      },
      {
        title: 'POP',
        subtitle: 'Procedimento Operacional Padrão',
        icon: mdiPaperclip,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('POP') },
      },
      {
        title: 'FORM',
        subtitle: 'Formulário',
        icon: mdiFileDocument,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('FORM') },
      },
      {
        title: 'ANEX',
        subtitle: 'Anexo',
        icon: mdiPaperclip,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('ANEX') },
      },
      {
        title: 'MQ',
        subtitle: 'Manual de Qualidade',
        icon: mdiBookOpenVariant,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('MQ') },
      },
      {
        title: 'PP',
        subtitle: 'Politica de Privacidade',
        icon: mdiShieldAccountVariant,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('PP') },
      },
      {
        title: 'FLX',
        subtitle: 'Fluxograma',
        icon: sgqDocumentFlux,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('FLX') },
      },
      ],
    }
  },

  async created() {
    await this.getDocuments()
  },

  methods: {
    async getDocuments() {
      this.isLoadingDocuments = true

      try {
        const response = await axiosIns.get('api/v1/sgq/registration/index')
        this.documents = response.data?.data
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingDocuments = false
      }
    },

    filterDocumentByType(type) {
      this.documentType = type

      const filteredDocuments = this.documents.filter(document => {
        const documentType = document?.type

        return documentType?.toUpperCase() === type?.toUpperCase()
      })

      const updatedDocuments = filteredDocuments.map(document => ({
        ...document,
        identify: `${document.type}-${document.code}`,
      }))

      this.openModalDetails()

      return updatedDocuments
    },

    openModalDetails() {
      this.showDetailsModal = true
    },
  },
}
</script>

<style lang="scss">
.container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 130px;
  padding: 0px 40px 0px 40px;

  background: #3C3857;

  .image-content {
    display: flex;
    height: 200px;
  }

  .overlay-image {
    position: absolute;
    z-index: 2;
    top: -20px;
  }

  .image-header {
    position: relative;
    right: 20px;
    top: 15px;
  }

  span {
    font-size: 12px;
    font-style: normal;
    padding: 10px;

    h1 {
      font-weight: 700;
    }

    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(4, 0.5fr);
  justify-content: center;
  padding: 50px;
  padding-bottom: 70px;
  position: relative;
  left: 35px;

  column-gap: 0px;
  row-gap: 65px;
}

.cards-container :nth-child(7) {
  position: relative;
  left: 200px;

  grid-column-start: 3;
  grid-column-end: span 1;
}

.cards-container :nth-child(5) {
  position: relative;
  left: 200px;

  grid-column-end: span 1;
}

.cards-container :nth-child(6) {
  position: relative;
  left: 200px;
  grid-column-end: span 1;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  border: 0.4px solid #d1cae38e;
  border-radius: 5px;
  text-align: center;
  width: 80%;
  height: 200px;

  font-size: 10px;

  h1 {
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
