import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"pt-2 pb-2"},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.documentType)+" ")]),_c(VSpacer),_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"20px","width":"50%"}},[_c(VTextField,{staticStyle:{"top":"14px"},attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"label":'Buscar ' + _vm.documentType,"outlined":"","dense":""},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}}),_c(VBtn,{attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.openModal('create',_vm.documents[0])}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_c('span',[_vm._v(" Novo ")])],1),_c(VBtn,{attrs:{"color":"warning","outlined":""},on:{"click":function($event){return _vm.$emit('close-window')}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiArrowLeft)+" ")]),_c('span',[_vm._v(" Voltar ")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.filteredDocuments,"loading":_vm.isLoadingData,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"item.showDocument",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c(VBtn,{attrs:{"icon":"","color":"warning","loading":_vm.isLoadingFile === item.id},on:{"click":function($event){return _vm.showFileInBrowser(item.id)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiEye))])],1)],1)]}},{key:"item.tools",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"gap":"5px"}},[_c(VIcon,{attrs:{"medium":"","color":"info"},on:{"click":function($event){return _vm.openModal('details',item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c(VIcon,{attrs:{"medium":"","color":"error"},on:{"click":function($event){return _vm.handleDelete(item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}])}),_c(VDialog,{attrs:{"width":"980px"},model:{value:(_vm.showModalCreate),callback:function ($$v) {_vm.showModalCreate=$$v},expression:"showModalCreate"}},[_c('Create',{attrs:{"process":_vm.processes,"document-type":_vm.documentType,"occupations":_vm.occupations,"companies":_vm.companies},on:{"updatedTable":function($event){return _vm.getDataFromTable()},"close":function($event){_vm.showModalCreate = false}}})],1),_c(VDialog,{attrs:{"width":"980px"},model:{value:(_vm.showModalDetails),callback:function ($$v) {_vm.showModalDetails=$$v},expression:"showModalDetails"}},[_c('Details',{key:_vm.document.id,attrs:{"process":_vm.processes,"rawDocument":_vm.document,"occupations":_vm.occupations,"companies":_vm.companies},on:{"updatedTable":function($event){return _vm.getDataFromTable()},"close":function($event){_vm.showModalDetails = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }